<template>
	<div class="layout-profile">
		<div class="logo">
			<img src="./assets/layout/images/logo1.jpg" alt="">
			<!-- <span >CRAFT SPIRITS</span> -->
		</div>
		
		
		<!-- <div>
			
		</div>
		<button class="p-link layout-profile-link" @click="onClick">
			<span class="username">Администратор</span>
			<i class="pi pi-fw pi-cog"></i>
		</button>
		<transition name="layout-submenu-wrapper">
			<ul v-show="expanded">
				<li><button class="p-link p-link-hover"><i class="pi pi-fw pi-user"></i><span>Account</span></button></li>
				<li><button class="p-link p-link-hover"><i class="pi pi-fw pi-inbox"></i><span>Notifications</span><span class="menuitem-badge">2</span></button></li>
				<li><button class="p-link p-link-hover"><i class="pi pi-fw pi-power-off"></i><span>Logout</span></button></li>
			</ul>
		</transition>
			-->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				expanded: false
			}
		},
		methods: {
			onClick(event){
				this.expanded = !this.expanded;
				event.preventDefault();
			}
		}
	}
</script>

<style scoped>
.p-link:focus{
	box-shadow: 0 0 0 0.2rem rgb(35, 36, 40, 0.3);
}
.p-link-hover:hover {
	background: #8f8f8f;
}

.logo {
	font-family: 'Roboto', sans-serif;	
	font-size: 22px;
	display: flex;
	align-items: center;
}



.logo img {
	width: 100%;
	margin: 0px;
}

</style>