import axios from 'axios';
import firebase from "firebase";
import 'firebase/storage';

export default class CategoriesService {

    async getCategories() {
		const snapshot = await firebase.firestore().collection("categories").doc('products').get()
        var o = snapshot.data();
        var r = [];
        r.push(o);
        return r;
	}
    async getCategoryID() {
		const doc = await firebase.firestore().collection("categories").doc()

        return doc.id;
	}
    async setCategories(cat) {
        await firebase.firestore().collection("categories").doc('products').set(cat);
                    
        return ;
    }

    getTreeTableNodes() {
        return axios.get('assets/layout/data/treetablenodes.json')
                .then(res => res.data.root);
    } 

    getMenuItem () {
        return axios.get('assets/layout/data/menuItem.json').then(res => res.data.data);
    }

    default = {
            "key": "root",
            "data":{  
                "name":"Все",
                "products_total":"0",
                "description":"All",
                "type":"Folder"
            },
            "children":[]
        }    
}
