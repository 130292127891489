//import ProductService from '../../service/ProductService';


const state = () => ({
    globalOrder: {
        items: [
        // {
        //     productId: null,
        //     price: ,
        //     quantity: 1,
        // }
        ],
        customer: {
            name: '',
            phone: '',
            address: '',
        },
        total: {
            sum: '',
            weight: '',
            quantity: ''       
        }

    },
    cartCount: 0,
    globalProducts: []
})

const getters = {
    cartLenght: (state) => {
        return state.globalOrder.total.quantity
    }

}

const actions = {
    


}

const mutations = {
    setOrder (state, o) {
        state.globalOrder = o;
    },
    setCartCount (state, q) {
        state.cartCount = q;
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}