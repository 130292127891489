import axios from 'axios';
import ImageService from "../service/ImageService";
import firebase from "firebase";
import 'firebase/storage';

export default class ProductService {	
	imageService = new ImageService();
	async setProduct(p) {
		var ref;
		console.log(p)
		if (p.id) {
			// await firebase.firestore().collection("products").doc(p.id).set(p);
			ref = await firebase.firestore().collection("products").doc(p.id).set(p);
		} else {
			ref = firebase.firestore().collection("products").doc();
			p.id = ref.id;
			await ref.set(p);
		}
		return p.id;
	}

	getProductsSmall() {
		return axios.get('assets/layout/data/.json').then(res => res.data.data);
	}

	async getProduct(id) {
		const p = await firebase.firestore().collection('products').doc(id).get();
		var prod = p.data();

		var imageService = new ImageService();
		prod.images = await imageService.getImages(id);
		//prod.images = Object.assign({}, prod.images);
		if(prod.images.length > 0){
			//prod.image = await imageService.getImageUrl(id,prod.images[0]);
		} else {
			prod.image = '';
		}	
		return prod;

	}
	async getProductID() {
		const doc = await firebase.firestore().collection("products").doc()
        return doc.id;
	}


	async getProducts() {
		const snapshot = await firebase.firestore().collection('products').get()
		return snapshot.docs.map(doc => doc.data());
	}

	async getProductsByCatId(catId) {
		const arrCatId = [];

		await firebase.firestore().collection('products').where("categories", "array-contains", catId).get().then(
			(querySnapshot) => {
			querySnapshot.forEach((doc) => {
				arrCatId.push(doc.data())
			});
		})
		.catch((error) => {
			console.log("Error getting documents: ", error);
		});
		console.log(arrCatId);
		return arrCatId
			
	}

	async deleteProduct(id) {
		firebase.firestore().collection('products').doc(id).delete();
		this.imageService.deleteImages(id);
        
	}	

	getProductsWithOrdersSmall() {
		return axios.get('assets/layout/data/products-orders-small.json').then(res => res.data.data);
	}
}

/**
 * @param {File} file 
 */
// function uploadBlob(file, p) {
// 	const ref = firebase.storage().ref().child('images/' + file.name)
// 	// const ref = firebase.storage().ref('images/' + file.name);

//   // [START storage_upload_blob]
//   // 'file' comes from the Blob or File API
//   ref.put(file).then((snapshot) => {
//     console.log('Uploaded a blob or file!');
// 		console.dir(snapshot)
// 		set_UrlImage(p)
//   });
//   // [END storage_upload_blob]
// }

// async function set_UrlImage(prod) {
// 	const imageRef = firebase.storage().ref().child('images/' + prod.image);
// 	const url = await imageRef.getDownloadURL()
// 	document.querySelectorAll(`[prod-firebase-id="${prod.id}"]`).forEach(el => el.src = url )
// }