import firebase from "firebase";
import 'firebase/storage';

export default class ImageService {

    async getImages(oid) {
        var res = [];
        if(!oid){
            oid = "";
        } 
        const ref = await firebase.storage().ref().child('images/' + oid).listAll().catch((error) => {
            console.log("ERROR listAll(): " + error);
            return res;
          });
        ref.items.forEach((item) => {
            res.push(item.name);
        });
        res.sort();
        return res;
	}
    async getImageUrl(oid,file) {
        if(!oid){
           return "";
        }

        const u = await firebase.storage().ref().child('images/' + oid + '/' + file).getDownloadURL().catch((error) => {
            console.log("ERROR getDownloadURL: " + error);
            return "";
          });
        //console.log(u);

        return u;
	}    
    async uploadImage(oid,file) {
        if(!oid){
            oid = "";
        } else {
            oid +="/";
        }
		const ref = await firebase.storage().ref().child('images/' + oid + file.name);
        await ref.put(file);
        console.log('Uploaded file! ' + file.name);
        return ;
	}
    async deleteImages(id) {
        if(!id){
            return;
        } 
        await firebase.storage().ref().child('images/' + id ).delete().catch((error) => {
            console.log(error);
          });
	}
    
    async deleteImage(id, file) {
        if(!id){
            return;
        } 
        await firebase.storage().ref().child('images/' + id + '/' + file ).delete().catch((error) => {
            console.log(error);
          });
	}
}
