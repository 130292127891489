<template>
	<div class="layout-topbar">
		<button class="p-link layout-menu-button" @click="onMenuToggle">
			<span class="pi pi-bars"></span>
		</button>
		<div class="layout-topbar-icons">
			<span class="layout-topbar-search">
				<InputText type="text" placeholder="Search" />
				<span class="layout-topbar-search-icon pi pi-search"></span>
			</span>
			<button class="p-link">
				<span class="layout-topbar-item-text">Events</span>
				<span class="layout-topbar-icon pi pi-calendar"></span>
				<span class="layout-topbar-badge">5</span>
			</button>
			<button class="p-link">
				<span class="layout-topbar-item-text">Cart</span>
				<router-link to="/cart">
				<span class="layout-topbar-icon pi pi-shopping-cart"></span>
				</router-link>
				<span class="layout-topbar-badge">{{globalOrder ? globalOrder : 0}}</span>
			</button>
		</div>

		
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	data () {
		return {

		}
	},

	computed: mapState({
        globalOrder: state => state.orders.cartCount,
    }),

	methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		
    },
	watch: {
		updatedCart: function () {
		console.log(this.cartLenght)
	},
	}
}
</script>

<style scoped>
a {
	color: inherit;
}

.p-link:focus{
	box-shadow: 0 0 0 0.2rem rgb(35, 36, 40, 0.3);
}
</style>